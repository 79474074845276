<template>
  <!-- Modal -->
  <div class="modal" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de documentos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label># Radicado <i class="fas fa-question-circle" title="Año y consecutivo de radicación"></i></label>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Clase de Documento:</label>
              <select v-model="item.tcr_id" class="form-control col-md-7">
                <option v-for="tpocor in tpocors" v-bind:value="tpocor._id">
                  {{ tpocor.tcr_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Año:</label>
              <input type="number" min="0" class="form-control col-md-7" v-model="item.dcm_ano">
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Número consecutivo:</label>
              <input type="number" min="0" class="form-control col-md-7" v-model="item.dcm_cdgo">
            </div>
            <hr>
            <label>Fecha Radicación <i class="fas fa-question-circle" title="Rango de fechas de registro o creación en el sistema."></i></label>
            <div class="form-group row">              
              <label class="col-md-5 col-form-label">(Desde):</label>
              <input type="date" class="form-control col-md-7" v-model="item.dcm_fcharad_ini">
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">(Hasta):</label>
              <input type="date" class="form-control col-md-7" v-model="item.dcm_fcharad_fin">
            </div>
            <hr>
            <label>Fecha Documento <i class="fas fa-question-circle" title="Rango de fechas del documento o expediente."></i></label>
            <div class="form-group row">              
              <label class="col-md-5 col-form-label">(Desde):</label>
              <input type="date" class="form-control col-md-7" v-model="item.dcm_fchaini">
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">(Hasta):</label>
              <input type="date" class="form-control col-md-7" v-model="item.dcm_fchafin">
            </div>
            <hr>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Dependencia:</label>
              <select v-model="item.dep_id" class="form-control col-md-7">
                <option v-for="dependencia in dependencias" v-bind:value="dependencia._id">
                  {{ dependencia.dep_nmbre + ' (' + dependencia.dep_cdgo + ')' }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Serie:</label>
              <select v-model="item.ser_id" class="form-control col-md-7">
                <option v-for="serie in series" v-bind:value="serie._id">
                  {{ serie.ser_nmbre + ' (' + serie.ser_cdgo + ')' }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Tipo de solicitud:</label>
              <select v-model="item.tpq_id" class="form-control col-md-7">
                <option v-for="tpopqr in tpopqrs" v-bind:value="tpopqr._id">
                  {{ tpopqr.tpq_nmbre }}
                </option>
              </select>
            </div>
            <!--
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Entidad:</label>
              <input type="text" class="form-control col-md-7" v-model="item.dcm_entidad">
            </div>
            -->
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Entidad / Asunto / Nombre del expediente / Ciudad / Índice:</label>
              <input type="text" class="form-control col-md-7" v-model="item.$text">
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Correo electrónico:</label>
              <input type="text" class="form-control col-md-7" v-model="item.dcm_email">
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Necesita Respuesta:</label>
              <input type="checkbox" class="form-control col-md-7" v-model="item.dcm_rspsta">
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Página web:</label>
              <input type="checkbox" class="form-control col-md-7" v-model="item.dcm_web">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="searchItems">Buscar documentos</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      //item: {}
      tpocors: [],
      dependencias: [],
      series: [],
      tpopqrs: []
    }
  },
  mounted: function() {

  },
  created: function() {
    this.fetchTpocor();
    this.fetchDependencia();
    this.fetchSerie();
    this.fetchTpopqrs();
  },
  methods: {
    fetchTpocor()
    {
      //alert(String(this.$store.state.company));
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.tcr_nmbre > b.tcr_nmbre) { return 1; };
          if (a.tcr_nmbre < b.tcr_nmbre) { return -1; };
          return 0;
        });
        this.tpocors = list;
      });
    },
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.dep_nmbre > b.dep_nmbre) { return 1; };
          if (a.dep_nmbre < b.dep_nmbre) { return -1; };
          return 0;
        });
        this.dependencias = list;
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.ser_nmbre > b.ser_nmbre) { return 1; };
          if (a.ser_nmbre < b.ser_nmbre) { return -1; };
          return 0;
        });
        this.series = list;
      });
    },
    fetchTpopqrs() {
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.tpq_nmbre > b.tpq_nmbre) { return 1; };
          if (a.tpq_nmbre < b.tpq_nmbre) { return -1; };
          return 0;
        });
        this.tpopqrs = list;
      });
    },
    searchItems(){
      if (JSON.stringify(this.item) != '{}'){
        this.$emit('search', this.item);
      } else {
        alert('No hay ningún criterio de búsqueda.');
      }
    }
  } // END METHODS
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 50px;
    width: 500px;
    height: 100%;
}
*/
</style>
